import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import blogStyles from "./blog.module.css"
import SEO from "../components/seo"

const BlogPage = () => {
  const data = useStaticQuery(graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            date
          }
          fields {
            slug
          }
        }
      }
    }
  }
`)

return (
  <Layout>
    <SEO title="Blog" />
    <h1>Blog page</h1>
    <p>Welcome to my blog</p>
    <ol className={blogStyles.posts}>
      {data.allMarkdownRemark.edges.map((edge) => {
        return (
          <Link to={`/blog/${edge.node.fields.slug}`}>
            <li className={blogStyles.post}>
              <h2>{ edge.node.frontmatter.title }</h2>
              <p>{ edge.node.frontmatter.date }</p>
            </li>
          </Link>
        )
      })}
    </ol>
  </Layout>
)
}
export default BlogPage
